import React from 'react'
import { useSelector } from 'react-redux'
import sanitizeHtml from 'sanitize-html'
import { ALLOWED_ATTRIBUTES, ALLOWED_TAGS } from '../conf/constants'

export default function ChosenReward(props) {
  const { reward, onConfirm, onBack } = props
  const content = useSelector((state) => state.translation.content)
  console.log(reward)

  return (
    <div className={`subreward subreward--choosen`}>
      <div>
        <h3>
          {sanitizeHtml(content['content.' + reward.title], {
            allowedTags: [],
            allowedAttributes: [],
          })}
        </h3>
        <p>
          {sanitizeHtml(content['content.' + reward.description], {
            allowedTags: [],
            allowedAttributes: [],
          })}
        </p>
        {reward.type === 'VOUCHER' && reward.usageData &&
          <p className="subreward__voucher">
            {reward.usageData.voucher}
          </p>
        }
        <div
          dangerouslySetInnerHTML={{
            __html: sanitizeHtml(content['content.' + reward.image], {
              allowedTags: ALLOWED_TAGS,
              allowedAttributes: ALLOWED_ATTRIBUTES,
            }),
          }}
          style={{ maxWidth: '100%' }}
        ></div>
      </div>
      {onConfirm && (
        // <>
        //   {reward.usageData.voucherData.isPossibleToUse && (
        <div className='subreward__button-block'>
          {/*<div className="subreward__voucher">*/}
          {/*  <div>{reward.usageData.voucher}</div>*/}
          {/*</div>*/}
          <button
            onClick={onBack}
            className={`btn btn--secondary`}
            rel='noopener noreferrer'
          >
            Zpět
          </button>
          <button
            onClick={onConfirm}
            className={`btn btn--primary`}
            rel='noopener noreferrer'
          >
            Vyzvednout odměnu
          </button>
        </div>
        //   )}
        //   {!reward.usageData.voucherData.isPossibleToUse && (
        //     <button className={`btn btn--primary`} disabled={true}>
        //       Vyzvednuto
        //     </button>
        //   )}
        // </>
      )}
    </div>
  )
}
